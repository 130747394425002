import styled from 'styled-components'

import { Container } from 'common/UI'
import { IframeEmbedStoryblok } from 'common/types'

type Props = {
  block: IframeEmbedStoryblok
}

export const Iframe = ({ block, ...props }: Props): JSX.Element => {
  const { url } = block

  return (
    <Container {...props}>
      <Wrapper>
        <iframe
          title="Embedded Iframe"
          width="100%"
          height="100%"
          style={{ border: 0 }}
          loading="lazy"
          allowFullScreen
          src={url}
        />
      </Wrapper>
    </Container>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 1rem 0;

  height: 30rem;
  width: 100%;

  ${({ theme }) => theme.media.md} {
    height: 40rem;

    padding: 2rem 0;
  }
`
